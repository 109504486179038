/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../../intl.js";
import "../../core/has.js";
import t from "../../core/Error.js";
import { clamp as n } from "../../core/mathUtils.js";
import { mappedFind as e } from "../../core/maybe.js";
import { convertDateFormatToIntlOptions as o, formatTimestamp as l, formatTimeOnly as r, formatDateOnly as i, formatDate as u } from "../../intl/date.js";
import { utc as a } from "../../time/timeZoneUtils.js";
import { isString as m, isNumber as s } from "./dataUtils.js";
import { DateTime as f, FixedOffsetZone as c } from "luxon";
import { getLocale as d } from "../../intl/locale.js";
const y = a,
  p = {
    date: null,
    time: null,
    timeZoneOffset: null
  },
  h = new Map([["hours", 3600], ["minutes", 60], ["seconds", 1], ["deciseconds", .1], ["centiseconds", .01], ["milliseconds", .001]]),
  S = "yyyy-MM-dd",
  v = "TT";
var M;
!function (t) {
  t.HM = "HH:mm", t.HMS = "HH:mm:ss", t.HMS_MS = "HH:mm:ss.SSS";
}(M || (M = {}));
const O = [M.HMS_MS, M.HMS, M.HM, v],
  w = "latn",
  H = new Set(["date-only", "time-only", "timestamp-offset"]),
  Z = t => "valueAsDate" in t;
function j(t) {
  return {
    locale: d(),
    numberingSystem: w,
    zone: t ?? y
  };
}
function x(t) {
  return t?.isValid ? t.toMillis() : null;
}
function g(t, n) {
  return null === t ? null : f.fromMillis(t, j(n));
}
function I(t, n) {
  if (null == t || !m(t) || "" === t) {
    if (n) {
      const t = f.local({
        zone: n
      });
      return {
        date: null,
        time: null,
        timeZoneOffset: t.isValid ? t.offset.toString() : null
      };
    }
    return p;
  }
  const e = f.fromISO(t, {
    setZone: !0
  });
  return {
    date: e.toFormat(S, j()),
    time: e.toFormat(M.HMS_MS, j()),
    timeZoneOffset: e.offset.toString()
  };
}
function V(t, n) {
  if (null == t || !s(t) || isNaN(t)) return p;
  const e = g(t, n);
  return e ? {
    date: e.toFormat(S, j(n)),
    time: e.toFormat(v, j(n))
  } : p;
}
function F(t) {
  switch (t.type) {
    case "date":
    default:
      return o("short-date-short-time");
    case "date-only":
      return o("short-date");
    case "time-only":
      return o("short-time");
    case "timestamp-offset":
      return {
        ...o("short-date-short-time"),
        timeZone: void 0,
        timeZoneName: "short"
      };
  }
}
function T(t, n, e) {
  if (!t || null == n) return null;
  switch (t.type) {
    case "date":
      return u(n, e);
    case "date-only":
      return i(n, e);
    case "time-only":
      return r(n, e);
    case "timestamp-offset":
      return l(n, e);
    default:
      return null;
  }
}
function D(t) {
  const {
    dateComponent: n,
    defaultTimeZone: e,
    timeComponent: o,
    timeZoneComponent: l,
    oldValue: r
  } = t;
  if (!n?.value || !o?.value) return null;
  const {
      year: i,
      month: u,
      day: m
    } = f.fromFormat(n.value, S),
    {
      hour: s,
      minute: d,
      second: y,
      millisecond: p
    } = z(o, a) ?? f.now(),
    h = I(r, e),
    v = null != l?.value ? parseInt(l.value, 10) : null != h.timeZoneOffset ? parseInt(h.timeZoneOffset, 10) : new Date().getTimezoneOffset(),
    M = c.instance(v),
    O = f.fromObject({
      year: i,
      month: u,
      day: m,
      hour: s,
      minute: d,
      second: y,
      millisecond: p
    }, {
      zone: M
    });
  return O.isValid ? O.toISO({
    includeOffset: !0
  }) : null;
}
function b(t, n = "system") {
  return f.fromJSDate(t, {
    zone: n
  }).isInDST;
}
function C(t) {
  const {
      dateComponent: n,
      timeComponent: e,
      timeZone: o,
      max: l,
      min: r,
      oldValue: i
    } = t,
    u = z(n, o);
  let a = !!t.applyRange;
  if (!u) return null;
  let m = null;
  if (null != e?.value) {
    const t = z(e, o),
      n = Date.now(),
      r = null != l && l < n ? l : n,
      i = f.fromMillis(r, j(o)),
      a = u || i,
      {
        year: s,
        month: c,
        day: d
      } = a,
      {
        hour: y,
        minute: p,
        second: h,
        millisecond: S
      } = t || i;
    m = a.set({
      year: s,
      month: c,
      day: d,
      hour: y,
      minute: p,
      second: h,
      millisecond: S
    });
  } else {
    const t = g(A({
        value: i ?? Date.now(),
        max: l,
        min: r
      }), o),
      {
        year: n,
        month: e,
        day: s
      } = u,
      {
        hour: f,
        minute: c,
        second: d,
        millisecond: y
      } = t;
    a = !0, m = u.set({
      year: n,
      month: e,
      day: s,
      hour: f,
      minute: c,
      second: d,
      millisecond: y
    });
  }
  if (!m.isValid) return null;
  const s = m.toMillis();
  return a ? A({
    value: s,
    max: l,
    min: r
  }) : s;
}
function z(t, n) {
  if (null == t?.value || Array.isArray(t.value)) return null;
  let e = null;
  return e = Z(t) ? f.fromFormat(t.value, S, j(n)) : J(t.value), e?.isValid ? e : null;
}
function N(t) {
  const {
    max: n,
    min: e,
    value: o
  } = t;
  return !isNaN(o) && !(null != n && o > n) && !(null != e && o < e);
}
function A(t) {
  const {
    max: e,
    min: o,
    value: l
  } = t;
  return null != e && null != o ? n(l, o, e) : null != e && l > e ? e : null != o && l < o ? o : l;
}
function U(n) {
  if (!n) return "";
  const e = n.split(".").at(0);
  if (!e || e.length < 1) throw new t("invalid time-only field", `Cannot parse valid time-only field value from ${n}`);
  const o = e.split(":"),
    l = new Array(3);
  for (let t = 0; t < 3; t++) {
    const n = o.at(t) ?? "";
    l[t] = n.padStart(2, "0");
  }
  return l.join(":");
}
function _(t, n) {
  switch (n) {
    case "date":
      return t.getTime();
    case "date-only":
      return t.toISODate();
    case "time-only":
      return U(t.toISOTime(!0, !1));
    case "timestamp-offset":
      return t.toISOString(!1);
    default:
      return null;
  }
}
function k(t) {
  return !!t && "object" == typeof t && "getTime" in t && "toISOString" in t && "timeZone" in t;
}
function E(t) {
  if (!t || !m(t)) return null;
  const n = f.fromFormat(t, S);
  return n.isValid ? n : null;
}
function J(t) {
  return t && m(t) ? e(O, n => {
    const e = f.fromFormat(t, n);
    return e.isValid ? e : null;
  }) ?? null : null;
}
function R(t) {
  if (!t || !m(t)) return null;
  const n = f.fromISO(t);
  return n.isValid ? n : null;
}
function $(t) {
  const {
    type: n,
    range: e,
    value: o
  } = t;
  if (null == o || !e || o === e.min || o === e.max) return !0;
  if ("date" === n) return N({
    value: o,
    min: e.min,
    max: e.max
  });
  const {
    rawMax: l,
    rawMin: r
  } = e;
  let i = null,
    u = null,
    a = null;
  switch (n) {
    case "date-only":
      i = E(l), u = E(r), a = E(o);
      break;
    case "time-only":
      i = J(l), u = J(r), a = J(o);
      break;
    case "timestamp-offset":
      i = R(l), u = R(r), a = R(o);
  }
  return !a || N({
    value: a.valueOf(),
    min: u?.valueOf(),
    max: i?.valueOf()
  });
}
export { M as TimeStampOffsetPattern, _ as arcadeDateToFieldValue, A as clampValueToRange, g as createDateTimeFromFieldValue, H as dateFieldsWithStringFieldValue, E as dateOnlyToDateTime, $ as dateTimeIsInRange, x as dateTimeToFieldValue, S as defaultDatePattern, v as defaultTimePattern, j as getDefaultLocaleOptions, D as getISOFieldValueFromDateComponents, F as getIntlOptionsForField, T as getLabelForDateFieldValue, z as getLocalDateTimeFromComponent, C as getUnixFieldValueFromDateComponents, k as isArcadeDate, Z as isCalciteDateComponent, b as isInDST, U as normalizeTimeOnlyString, w as numberingSystem, I as prepareISOFieldValueForDateComponents, V as prepareUnixFieldValueForDateComponents, O as supportedTimeFormats, J as timeOnlyToDateTime, h as timeResolutionToStepMap, R as timestampOffsetToDateTime, N as valueIsInRange };